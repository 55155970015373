import React from 'react'
import { Link } from './'

export default function Button({ block, children, className, href, isActive, onClick, type, ...rest }) {
  const Component = href ? Link : 'button'
  const classNameStr = `
    c-button
    c-button--${type}
    ${block ? 'c-button--block': ''}
    ${className}
    ${isActive ? 'is-active' : ''}
  `

  return (
    <Component
      className={classNameStr}
      href={href}
      onClick={onClick}
      {...rest}
    >
      {children}
    </Component>
  )
}

Button.defaultProps = {
  className: '',
  type: 'default',
}
