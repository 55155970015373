import React from 'react'
import { format } from 'date-fns'
import Link from './Link'
import FixtureEntryDisplay from './FixtureEntryDisplay'


export default function Fixture({ type, fixture, divisions, fixtures, showDivision=true, showOrganisation=false }) {
  const {
    start_time: time,
    status,
    division,
    home_entry: home,
    away_entry: away,
    gallery
  } = fixture
  return (
    <div
      className={`
        c-fixture
        c-fixture--${type}
      `}
    >
      {fixture.number && <span className="c-fixture__number u-aux">#{fixture.number}</span>}
      <span className="c-fixture__home o-team">
        {/*<span className="o-team__logo"></span>*/}
        <FixtureEntryDisplay entry={home} divisions={divisions} fixtures={fixtures}/>
      </span>
      {['complete', 'in_progress'].indexOf(status) > -1 && (
        <Link prefetch href={`/report?fixture=${fixture.id}`} className="c-fixture__scores">
          <span className="">
            {home && home.result.type == 'standard/scores' ? `${home.result.score} – ${away.result.score}` : ''}
            {home && home.result.type == 'forfeit/against' ? `F – 5` : ''}
            {home && home.result.type == 'forfeit/for' ? `5 – F` : ''}
            {!home || !away ? ` – ` : ''}
          </span>
        </Link>
      )}
      {['complete', 'in_progress'].indexOf(status) == -1 && (
        <span className="c-fixture__info">
          {format(time, 'HH:mm')}
        </span>
      )}
      <span className="c-fixture__away o-team">
        {/*<span className="o-team__logo"></span>*/}
        <FixtureEntryDisplay entry={away} divisions={divisions} fixtures={fixtures}/>
      </span>
      {showDivision && <span className="c-fixture__extra u-aux">
        {showOrganisation && division.competition && division.competition.organisation && division.competition.organisation.name + ' - '} {division && division.name}
      </span>}

      {/*<span className="c-fixture__extra u-aux">
        {gallery ? <a href={`/gallery/` + gallery.id }>View Photos</a> :''}
      </span>*/}
    </div>
  )
}

Fixture.defaultProps = {
  type: 'default',
}
