import React from 'react'
import { format } from 'date-fns'
import Fixture from '../components/Fixture'

export default function NextFixtures({ className, results, divisions, organisationId }) {
  
  return (
    <div className={className}>
      <h1>{'Latest Results'}</h1>
      <h2 className="u-aux">{format(results[0].start_time, 'Do MMMM YYYY')}</h2>
      {results.map(result => <Fixture key={result.id} fixture={result} fixtures={results} divisions={divisions} showOrganisation={result.division && result.division.competition && result.division.competition.organisation_id != organisationId}/>)}
    </div>
  )
}
