import React from 'react'

export default function GoalieStat({ member, team, stats, position, under_18 }) {

  return (
    <tr>
      {!under_18 && <td>{member.given_name} {member.family_name} {position && <span className="aux">({position})</span>}</td>}
      {under_18 && <td>{member.given_name.substring(0,1)} {member.family_name} {position && <span className="aux">({position})</span>}</td>}
      <td>{team}</td>
      <td className="u-center">{stats && stats.played}</td>
      <td className="u-center">{stats && stats.goals_against}</td>
      <td className="u-center">{stats && stats.goals_against_avg}</td>
      <td className="u-center">{stats && stats.adjusted_play_time}%</td>

    </tr>
  )
}
