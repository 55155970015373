import React from 'react'

export default function ButtonGroup({ children }) {
  return (
    <div className="c-button-group">
      {React.Children.map(children, child => (
        React.cloneElement(child, { className: 'c-button-group__button' })
      ))}
    </div>
  )
}
