import React from 'react'
import { format } from 'date-fns'
import Fixture from '../components/Fixture'

export default function InProgress({ className, inProgress, divisions, fixtures, organisationId }) {
  return (
    <div className={className}>
      <h1>{'Now Playing'}</h1>
      {inProgress.length > 0 && inProgress.map(fixture => <Fixture key={fixture.id} fixtures={fixtures} fixture={fixture} divisions={divisions} showOrganisation={fixture.division && fixture.division.competition && fixture.division.competition.organisation_id != organisationId} />)}
    </div>
  )
}
