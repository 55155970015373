import React from 'react'
import Link from './Link'

export default function TeamStanding({ name, teamId, divisionEntryId, stats }) {
  return (
    <tr>
      <td>
      <Link prefetch href={`/team?team=${teamId}&divisionEntry=${divisionEntryId}`}>
        {name}
      </Link>
      </td>
      <td className="u-center">{stats.played}</td>
      <td className="u-center u-show-mobile-landscape">{stats.won}</td>
      <td className="u-center u-show-mobile-landscape">{stats.drawn}</td>
      <td className="u-center u-show-mobile-landscape">{stats.lost}</td>
      <td className="u-center u-show-tablet-portrait">{stats.goals_for}</td>
      <td className="u-center u-show-tablet-portrait">{stats.goals_against}</td>
      <td className="u-center u-show-mobile-landscape">{stats.goal_diff}</td>
      <td className="u-center">{stats.points}</td>
    </tr>
  )
}
