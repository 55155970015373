import React, { Component } from 'react'
import { Button } from './'

export default class Dropdown extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isOpen: false
    }

  }

  handleOptionSelect = (e, option) => {
    e.preventDefault()
    this.props.onOptionSelect(option);
    this.setState({isOpen: false})
  }

  toggle = () => {
    this.setState({
      isOpen: !this.state.isOpen
    })
  }

  render() {
    const { activeId, options, title, className, baseUrl, onOptionSelect, urlFormatter} = this.props
    const { isOpen } = this.state
    const active = options.find((o) => o.id.toString() == activeId,10)

    const classNameStr = `
      c-dropdown
      ${className}
      ${isOpen ? 'c-dropdown--open' : ''}
    `

    const activeName = active ? active.name : ''

    return (
      <div className={classNameStr}>
        <Button onClick={this.toggle}>{activeName}<ion-icon name="arrow-dropdown" /></Button>
        <ul className="menu">
          {options.map((o,index) =>
            <li key={index}>
              {onOptionSelect && <a href="#" onClick={(e) => this.handleOptionSelect(e, o)}>{o.name}</a>}
              {urlFormatter && <a href={urlFormatter(o)}>{o.name}</a>}
              {baseUrl && <a href={baseUrl + o.id}>{o.name}</a>}
            </li>
          )}
        </ul>
      </div>
    )
  }
}
