import React from 'react'

export default function PinMapTeamListItem({ teamGroup }) {
    if (teamGroup) {
        const club = teamGroup[0].club
        return (
          <div className="pin-map-team-list-item">
            <div className="pin-map-team-list-item-logo">
                {club.logo_image && <img width="50%" src={club.logo_image.file.uri} />}
            </div>
            <div className="pin-map-team-list-item-name">
                {teamGroup.map((t) => {
                    return (<div key={t.id}>{t.name} ({t.ageGroup.name})</div>)

                })}
            </div>
      
          </div>
        )
    }

    return (<p>No Teams currently train here.</p>)

}
