import React from 'react'
import organisation from '../config/organisation';
import { fetchOrganisation } from '../api/organisations'
import { fetchPages } from '../api/pages';


export default (Page) => {
    return class extends React.Component {
        static async getInitialProps({req, query}) {
          const organisationId = organisation(req)
          const org = await fetchOrganisation(organisationId)
          const pages = await fetchPages(organisationId)
          let props = {
            organisationId: organisationId,
            organisation: org,
            pages: pages
          }
          if(Page.getInitialProps) {

            const ctx = { req, query, props}
            props = Object.assign(props, await Page.getInitialProps(ctx))
          }
          return props
        }

        render() {
            return <Page {...this.props}/>
        }

    }
}
