import { parse, differenceInDays } from 'date-fns'
import { api } from './api'
import organisation from '../config/organisation'

export async function fetchFixture(id) {
  const apiInst = await api()
  const response = await apiInst.get(`fixtures/${id}?include=gallery`)
  return response.data
}

export async function fetchEvents(id) {
  const apiInst = await api()
  const response = await apiInst.get(`/events?fixture_id=${id}`)
  return response.data
}

export async function fetchParticipants(id) {
  const apiInst = await api()
  const response = await apiInst.get(`/participants?fixture_id=${id}`)
  return response.data
}

export async function fetchResults(filters) {
  return fetchFixtures(Object.assign({'period': 'past', 'include': 'division.competition.organisation,gallery' }, filters));

}

export async function fetchFutureFixtures(filters) {
  return fetchFixtures(Object.assign({'period': 'future', 'include': 'division.competition.organisation'}, filters));
}

export async function fetchUpcomingFixtures(organisationIds) {
  const response = await fetchFixtures({'period': 'future', 'status': 'not_started', 'include': 'division.competition.organisation', 'organisation_ids': organisationIds });
  return filterSingleDay(response.data)
}

export async function fetchRecentResults(organisationIds) {
  const response = await fetchFixtures({'period': 'past', 'include': 'division.competition.organisation', 'organisation_ids': organisationIds });
  return filterSingleDay(response.data, true)
}

export async function fetchInProgress(organisationIds) {
  const response = await fetchFixtures({'status': 'in_progress', 'include': 'division.competition.organisation', 'organisation_ids': organisationIds });
  return filterSingleDay(response.data, true)
}

async function fetchFixtures(params) {
  const query = Object.keys(params).map((key) => key + '=' + params[key])
  const apiInst = await api()
  const response = await apiInst.get('fixtures?' + query.join('&'))

  return response.data
}

function filterSingleDay(fixtures, reverse = false) {
  const fn = reverse ? 'reduceRight' : 'reduce'

  const output = fixtures[fn]((output, fixture) => {
    if (!output.done && output.fixtures.length) {
      const a = parse(output.fixtures[0].start_time)
      const b = parse(fixture.start_time)

      if (differenceInDays(a, b) === 0) {
        // the fixture is on the same day as the others in the output.
        output.fixtures.push(fixture)
      } else {
        // the fixture is on a different day, so stop processing.
        output.done = true
      }
    } else if (!output.done) {
      // the first fixture will always be included in the output
      output.fixtures.push(fixture)
    }

    return output
  }, { done: false, fixtures: [] })

  return output.fixtures
}
