import React from 'react';
import { format, parse } from 'date-fns';

export default ({ fixture, state: fixtureState }) => {
    if (fixture && fixture.home_entry) {
        const scores = fixtureState.scores || {};
        const state = fixtureState.state || {};

        const home = fixture.home_entry || {};
        const away = fixture.away_entry || {};

        const homeScore = scores[home.division_entry_id] || 0;
        const awayScore = scores[away.division_entry_id] || 0;

        const startTime = parse(fixture.start_time);
        const startTimeStr = format(startTime, 'Do MMMM YYYY [at] h:mm A');

        return (
            <div>
                <h1 className="scores">
                    <span className="scores-team scores-right">{home.name}</span>
                    <span className="scores-score scores-right">{state.isStarted ? homeScore : ''}</span>
                    <span className="scores-vs">–</span>
                    <span className="scores-score">{state.isStarted ? awayScore : ''}</span>
                    <span className="scores-team">{away.name}</span>
                </h1>
                <div className="meta-info">{startTimeStr}</div>
            </div>
        );
    } else {
        return (
            <div>
                <h1 className="scores">
                    <span className="scores-team scores-right">&nbsp;</span>
                    <span className="scores-score scores-right">&nbsp;</span>
                    <span className="scores-vs">–</span>
                    <span className="scores-score">&nbsp;</span>
                    <span className="scores-team">&nbsp;</span>
                </h1>
                <div className="meta-info">Loading...</div>
            </div>
        )
    }

};
