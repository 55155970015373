import React from 'react'
import Link from './Link'

export default function RegionNav({ className }) {
  return (
    <ul className={`c-region-nav ${className}`}>
      <li className="c-region-nav__item">
        <Link prefetch className="c-button c-button--subtle" href="https://west.brha.co.uk">North Wales</Link>
      </li>
      <li className="c-region-nav__item">
        <Link prefetch className="c-button c-button--subtle" href="https://northwest.brha.co.uk">North West</Link>
      </li>
      <li className="c-region-nav__item">
        <Link prefetch className="c-button c-button--subtle" href="https://midlands.brha.co.uk">Midlands</Link>
      </li>
      <li className="c-region-nav__item">
        <Link prefetch className="c-button c-button--subtle" href="https://south.brha.co.uk">South</Link>
      </li>
      <li className="c-region-nav__item">
        <Link prefetch className="c-button c-button--subtle" href="https://southwest.brha.co.uk">South West</Link>
      </li>

      <li className="c-region-nav__item">
        <Link prefetch className="c-button c-button--subtle" href="https://west.brha.co.uk">West</Link>
      </li>
      <li className="c-region-nav__item">
        <Link prefetch className="c-button c-button--subtle" href="https://yorkshire.brha.co.uk">Yorkshire</Link>
      </li>
    </ul>
  )
}
