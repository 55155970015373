import React, { Component } from 'react'
import Button from './Button'
import Hamburger from './Hamburger'
import Nav from './Nav'
import Link from './Link'

export default class Footer extends Component {

  constructor(props) {
    super(props)

    let associate = false

    if (props.regionId == 106 || props.regionId == 107) {
      associate = true
    }
    this.state = {
      associate: associate
    }
  }

  render() {
    const { associate } = this.state
    console.log(associate)
    return (
      <div className={`c-footer u-grid__footer`}>
          <div>
            <a href="https://www.facebook.com/groups/brhaofficial/"><ion-icon name="logo-facebook">&nbsp;</ion-icon></a>
            <a href="https://www.instagram.com/brha_official/"><ion-icon name="logo-instagram">&nbsp;</ion-icon></a>
            <a href="https://twitter.com/brha_hockey"><ion-icon name="logo-twitter">&nbsp;</ion-icon></a>
            <a href="https://www.youtube.com/channel/UC1U2QI0TIfRzQNYKB1q5wmw"><ion-icon name="logo-youtube">&nbsp;</ion-icon></a>
          </div>
          {this.props.regionId != 48 &&<div>
            <p>Images and Content are subject to Copyright {(new Date()).getFullYear()}</p>
            {!associate &&<p>British Rink Hockey Association</p>}
          </div>
          }
          {associate &&
           <div>
            <p>Associated with</p>
            <img src="/static/logo.svg" width="100" />
          </div>
          }
          {this.props.regionId == 48 &&
            <div>
              <p>© Copyright {(new Date()).getFullYear()} - BRHA Limited. A company limited by guarantee.</p>
               <p>Registered in England & Wales, number: 12266073. </p>
               <p>Registered Office: 71-75 Shelton Street, London, Greater London, WC2H 9JQ. </p>
            </div>

          }
          <div className="ukactive">
            <img src="/static/ukactive_Member_CMYK.png" alt="UKActive Member graphic"/>
          </div>
      </div>
    )
  }
}
