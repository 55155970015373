import { api } from './api';
import { getValue } from '../cache';

export async function fetchCompetitions(organisationId, seasonId) {
    const key = 'competitions-' + seasonId + '-organisation-' + organisationId
    const cacheTTL = 3.6e+6

    const value = await getValue(key, async function() {
      const apiInst = await api();
      const response = await apiInst.get('competitions?season_id=' + seasonId + '&organisation_id=' + organisationId);
      return response.data;
    }, cacheTTL)

    return value
}
