import React from 'react'

export default function Document({document}) {
  return (
    <div className="c-document">
      <h2>{document.title}</h2>
      <div className="u-aux">{document.description}</div>
      <div><a href={document.file.uri}>{document.file.name}</a></div>
    </div>
  )
}
