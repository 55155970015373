import React from 'react'
import NextLink from 'next/link'

export default function Link({ children, className, href, prefetch, ...rest }) {
  return (
    <NextLink href={href} prefetch={prefetch}>
      <a className={className} {...rest}>
        {children}
      </a>
    </NextLink>
  )
}

Link.defaultProps = {
  className: '',
}
