import React, { Component } from 'react'
import Button from './Button'
import Hamburger from './Hamburger'
import Nav from './Nav'
import RegionNav from './RegionNav'
import Link from './Link'

export default class Header extends Component {

  constructor(props) {
    super(props)

    let logoSrc = 'logo.svg'
    let scale = 0.8
    if (props.regionId == 106) {
      logoSrc = 'BRHA North West logo.png'
      scale = 1.2
    } else if (props.regionId == 107) {
      logoSrc = 'yorkshire.png'
    } else if (props.regionId == 108) {
      logoSrc = 'southwest.png'
      scale = 1.2
    } else if (props.regionId == 36) {
      logoSrc = 'BRHA South logo.png'
      scale = 1.2
    } else if (props.regionId == 52) {
      logoSrc = "BRHA West logo.png"
      scale = 1.2
    } else if (props.regionId == 143) {
      logoSrc = "BRHA midlands logo.png"
      scale = 0.8
    } else if (props.regionId == 161) {
      logoSrc = "IRON HOCKEY.png"
      scale = 1.2
    }
    this.state = {
      isActive: false,
      logoSrc: logoSrc,
      scale: scale
    }
  }

  toggle = () => {
    this.setState({
      isActive: !this.state.isActive
    })
  }

  render() {
    const { className, region, regionId, pages } = this.props
    const { isActive, logoSrc,  scale } = this.state

    return (
      <div className={`c-header u-grid__header ${className} ${isActive ? 'is-open' : ''}`}>
        <div className="c-header__slash c-header__slash-1"></div>
        <div className="c-header__slash c-header__slash-2"></div>
        <div className="c-header__slash c-header__slash-3"></div>
        <div className="c-header__decay"></div>
        <div className="c-header__top"></div>
        <h2 className="c-header__region">{regionId != 48 && `${region}`}</h2>
        <Button type="subtle" onClick={this.toggle} className="c-header__flyout-icon c-header__square u-hide-tablet-landscape">
          <Hamburger isActive={isActive} />
        </Button>

        <Link prefetch href="/" className="c-header__logo c-header__square u-vertical-center">
          <img src={`/static/${logoSrc}`} style={{transform: `scale(${scale}, ${scale}  )`}} />
        </Link>
        <Nav className="c-header__nav" pages={pages} />
        {regionId == 48 &&
          <RegionNav className="c-header__region-nav" /> }
      </div>
    )
  }
}
