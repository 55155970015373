import { api } from './api';
import organisation from '../config/organisation';
import { getValue } from '../cache';
import { useRouter } from 'next/router'

export async function fetchOrganisation(id) {
   const key = 'organisation-' + id
   const cacheTTL = 3.6e+6

   const value = await getValue(key, async function() {
     const apiInst = await api();
     const response = await apiInst.get('organisations/' + id).catch(function(e) {
       const router = useRouter()
       router.push('offline')

     })
     return response.data;
   }, cacheTTL)

   return value

}
