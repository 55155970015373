import React from 'react'
import { format } from 'date-fns'
import Link from './Link'

const ordinals = [0, 'st', 'nd', 'rd'];
function ordinal(n) {
  var index, value;
  value = parseInt(n) + 1;
  index = ~~(value / 10 % 10) - 1 ? value % 10 : 0;
  return value + (ordinals[index] || 'th');
};

function findDivisionName(id, divisions) {

  const division = divisions.data.find(function(d) {
    return d.id == id
  })
  if (division) {
    return division.name
  }

  return ""
}

function findFixture(id, fixtures) {
  return fixtures.find(function(d) {
    return d.id == id
  })
}

export default function FixtureEntryDisplay({ entry, divisions, fixtures }) {

  var loserEntry
  if (entry && entry.division_entry_id == null &&  entry.rules && entry.rules.loser_of) {
    loserEntry = findFixture(entry.rules.loser_of, fixtures)
  }

  var winnerEntry
  if (entry && entry.division_entry_id == null && entry.rules && entry.rules.winner_of) {
    winnerEntry = findFixture(entry.rules.winner_of, fixtures)
  }

  return (
    <span className="o-team__name">
      {entry && entry.division_entry_id && <Link prefetch href={`/team?team=${entry.team_id}&divisionEntry=${entry.division_entry_id}`}>
        {entry.name}
      </Link>}
      { winnerEntry &&
        `Winner of #${winnerEntry.number}`
      }
      { loserEntry &&
        `Loser of #${loserEntry.number}`
      }
      {entry && !entry.division_entry_id && entry.rules && entry.rules.division_position &&
        `${ordinal(entry.rules.division_position.position)} in ${findDivisionName(entry.rules.division_position.division_id, divisions)}`
      }
    </span>
  )
}
