import React, { Component } from 'react'
import { withScriptjs, withGoogleMap, GoogleMap, Marker, InfoWindow } from "react-google-maps"
const { SearchBox } = require("react-google-maps/lib/components/places/SearchBox");
import PinMapTeamList from "./PinMapTeamList"

export default class PinMap extends Component {

    constructor(props) {
      super(props)
      const openStates = []
      props.venues.forEach(function(v) {
        openStates[v.id] = false
      })
      this.state = {
        openStates:openStates,
        zoom: 5,
        lat: props.lat,
        lng: props.lng
      }

      this.closeWindow = this.closeWindow.bind(this)
      this.openWindow = this.openWindow.bind(this)
      this.onPlacesChanged = this.onPlacesChanged.bind(this)

      this.onMapMounted = this.onMapMounted.bind(this)
      this.onSearchBoxMounted = this.onSearchBoxMounted.bind(this)

    }

    closeWindow(id) {
        const clone = this.state.openStates.slice()
        const zoom = this.mapRef.getZoom();
        const centre = this.mapRef.getCenter();

        clone[id] = false
        this.setState({
            openStates: clone,
            lng: centre.lng(), 
            lat: centre.lat()
        })    }

    openWindow(id) {
        const clone = this.state.openStates.slice()
        const zoom = this.mapRef.getZoom();
        const centre = this.mapRef.getCenter();


        clone[id] = true
        this.setState({
            openStates: clone,
            lng: centre.lng(), 
            lat: centre.lat()
        })
    }

    onMapMounted(ref) {
        this.mapRef = ref
    }


    onSearchBoxMounted(ref) {
        this.searchBoxRef = ref
    }
    
    onPlacesChanged() {
        const places = this.searchBoxRef.getPlaces();
        if (places.length) {
        
        this.setState({
            lng: places[0].geometry.location.lng(),
            lat: places[0].geometry.location.lat()
        })
        }
    }

    render() {
      const { venues, onPlacesChanged} = this.props
      const { openStates, zoom, lng, lat } = this.state
      const VenueMap = withScriptjs(withGoogleMap((props) =>
            <GoogleMap
                ref={this.onMapMounted}
                zoom={zoom}
                center={{lat: lat, lng: lng}}
            >
                <SearchBox
                    ref={this.onSearchBoxMounted}
                    bounds={props.bounds}
                    controlPosition={google.maps.ControlPosition.TOP_LEFT}
                    onPlacesChanged={this.onPlacesChanged}
                    >
                <input
                type="text"
                placeholder="Location Search"
                style={{
                    boxSizing: `border-box`,
                    border: `1px solid transparent`,
                    width: `240px`,
                    height: `40px`,
                    marginTop: `10px`,
                    padding: `0 12px`,
                    borderRadius: `3px`,
                    boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
                    fontSize: `14px`,
                    outline: `none`,
                    textOverflow: `ellipses`,
                }}
                />
            </SearchBox>
                {venues.map((v) => {
                    return (<Marker key={v.id} position={{ lat: v.latitude, lng: v.longitude }} title={v.name} onClick={() => this.openWindow(v.id)}>
                                {openStates[v.id] && <InfoWindow onCloseClick={() => this.closeWindow(v.id)}>
                                    <div>
                                    <h3>{v.name}</h3>
                                    <div>
                                        {v.teams && <PinMapTeamList teams={v.teams.data}/>}
                                    </div>
                                    </div>
                                </InfoWindow>}
                            </Marker>
                        )
        
                })}
            </GoogleMap>
            ))
  
    return (
      <VenueMap
              googleMapURL="https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=AIzaSyC_yoRfwa1LEn1Y212X5L27E0dhu784mxY"
              loadingElement={<div style={{ height: `100%` }} />}
              containerElement={<div style={{ height: `600px` }} />}
              mapElement={<div style={{ height: `100%` }} />}
              />
  
    )
    }
  }
