const organisations = {
  'localhost:4002': 48,
  'south.localhost:4002': 36,
  'wales.localhost:4002': 52,
  'north-west.localhost:4002': 106,
  'yorkshire.localhost:4002': 107,
  'south-west.localhost:4002': 108,
  'midlands.localhost:4002': 143,
  '3on3ironhockey.localhost:4002': 161,

  'midlands.brha.co.uk': 143,
  'south.brha.co.uk': 36,
  'west.brha.co.uk': 52,
  'northwest.brha.co.uk': 106,
  'yorkshire.brha.co.uk': 107,
  'southwest.brha.co.uk': 108,
  '3on3ironhockey.brha.co.uk': 161,
  'brha.co.uk': 48

}

const parentOrganisationId = 48;

module.exports = function(req) {
  var organisationId
  if (process.browser) {
    organisationId = organisations[window.location.host]
  } else if (req.headers['x-forwarded-host']){
    organisationId = organisations[req.headers['x-forwarded-host']]
  } else {
    organisationId = organisations[req.headers.host]
  }

  if (!organisationId) {
    organisationId = 36
  }



  return organisationId

}
