import React from 'react'
import { format } from 'date-fns'
import Button from './Button'

export default function Nav({ article }) {
  return (
    <div className="c-news-article">
      <h1>{article.headline}</h1>
      <h2 className="u-aux">{format(article.created_at, 'Do MMMM YYYY')}</h2>
      <div className="u-text-body" dangerouslySetInnerHTML={{__html: article.html}} />
      <Button href={`news/${article.id}`} className="c-news-article__more">View</Button>

    </div>
  )
}
