import axios from 'axios';
import getConfig from 'next/config'
const { publicRuntimeConfig } = getConfig()
const Agent = require('agentkeepalive');
const HttpsAgent = require('agentkeepalive').HttpsAgent;

const keepaliveHttpsAgent = new HttpsAgent({
  maxSockets: 100,
  maxFreeSockets: 10,
  timeout: 60000, // active socket keepalive for 60 seconds
  freeSocketTimeout: 30000, // free socket keepalive for 30 seconds
});

const keepAliveAgent = new Agent({
  maxSockets: 100,
  maxFreeSockets: 10,
  timeout: 60000, // active socket keepalive for 60 seconds
  freeSocketTimeout: 30000, // free socket keepalive for 30 seconds
});


export async function api() {

    return axios.create({
      httpsAgent: keepaliveHttpsAgent,
      HttpAgent: keepAliveAgent,
      baseURL: 'https://api.sportilyapp.com/',
      headers: {
          'Authorization': 'Bearer ZWJQYulPLtD3urFYau5Jyud9d1Bos5j2SkOwAkW0'
      }
    });
}

export default api;
