import React from 'react'

export default function Hamburger({ isActive }) {
  return (
    <span className={`hamburger hamburger--spin u-vertical-center ${isActive ? 'is-active' : ''}`}>
      <span className="hamburger-box">
        <span className="hamburger-inner"></span>
      </span>
    </span>
  )
}
