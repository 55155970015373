import React from 'react'
import { ButtonGroup, Dropdown } from './'

export default function SeasonDivisionSelector({ baseUrl, seasons, competitions, divisions, seasonId, competitionId, divisionId}) {
  return (
    <ButtonGroup>
      <Dropdown activeId={seasonId} options={seasons.data} baseUrl={`/${baseUrl}?season=`} title="SeasonSelector" />
      <Dropdown activeId={competitionId} options={competitions.data} baseUrl={`/${baseUrl}?season=${seasonId}&competition=`} title="CompetitionSelector" />
      <Dropdown activeId={divisionId} options={divisions.data} baseUrl={`/${baseUrl}?season=${seasonId}&competition=${competitionId}&division=`} title="DivisionSelector" />
    </ButtonGroup>
  )
}
