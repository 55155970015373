import React from 'react'
import { GalleryThumbnail } from './'

export default function GalleryPreview({ images }) {
  const sub = images.slice(0, 4);
  return (
    <div className="c-gallery">
      {sub.map((s, index) =>
        <GalleryThumbnail key={s.id} image={s} />
      )}
    </div>
  )
}
