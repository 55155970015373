import React from 'react'

export default function PlayerStat({ member, stats, position, under_18 }) {
  return (
    <tr>
      {!under_18 && <td>{member.given_name} {member.family_name} {position && <span className="aux">({position})</span>}</td>}
      {under_18 && <td> {member.given_name.substring(0,1)} {member.family_name} {position && <span className="aux">({position})</span>}</td>}
      <td className="u-center">{stats && stats.played}</td>
      <td className="u-center">{stats && stats.goals}</td>
      <td className="u-center u-show-mobile-landscape">{stats && stats.assists}</td>
      <td className="u-center u-show-mobile-landscape">{stats && stats.penalty_seconds/60}</td>
      <td className="u-center">{stats && stats.points}</td>

    </tr>
  )
}
