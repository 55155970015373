import { api } from './api';

export async function fetchDivisions(organisationIds, competitionId) {
    const apiInst = await api();
    let url = 'divisions?organisation_ids=' + organisationIds
    if (competitionId) {
      url = url + '&competition_id=' + competitionId
    }
    const response = await apiInst.get(url );
    return response.data;
}

export async function fetchDivision(id) {
    const apiInst = await api();
    const response = await apiInst.get('divisions/' + id);
    return response.data;
}
