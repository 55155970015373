var cache = require('memory-cache');

export async function getValue(key, fetchFunction, ttl) {
    let value = cache.get(key);
    if (!value) {
      value = await fetchFunction()
      cache.put(key, JSON.stringify(value), ttl)
    } else if (typeof value === 'string') {
      value = JSON.parse(value)
    }

    return value
}
