import React from 'react'

export default function TeamStat({ title, value }) {
  return (
    <div className="c-teamstat">
      <div className="stat-value">{value}</div>
      <div className="stat-label aux">{title}</div>
  </div>
  )
}
