import React from 'react'
import PinMapTeamListItem from "./PinMapTeamListItem"
export default function PinMapTeamList({ teams }) {
  
  const groupByClubId = (arr = []) => {
    let result = [];
    result = arr.reduce((r, a) => {
       r[a.club_id] = r[a.club_id] || [];
       r[a.club_id].push(a);
       return r;
    }, Object.create(null));
    return result;
  };
  const teamsByClub = groupByClubId(teams)
  const keys = Object.keys(teamsByClub)
  return (
    keys.map((k) => {
      const teamGroup = teamsByClub[k]

      return (<PinMapTeamListItem key={k} teamGroup={teamGroup} />)
    })
  )
}
