import { api } from './api';

export async function fetchPages(organisationId, excludeIds=[]) {
    const apiInst = await api();

    const response = await apiInst.get('pages?status=published&organisation_id=' + organisationId + '&exclude&summary');

    return response.data;
}

export async function fetchPage(id) {
    const apiInst = await api();

    const response = await apiInst.get('pages/' + id);

    return response.data;
}
