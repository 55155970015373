import React from 'react'
import { format } from 'date-fns'
import Fixture from '../components/Fixture'

export default function NextFixtures({ className, fixtures, divisions, organisationId }) {
  return (
    <div className={className}>
      <h1>{'Next Fixtures'}</h1>
      {fixtures.length > 0 && <h2 className="u-aux">{format(fixtures[0].start_time, 'Do MMMM YYYY')}</h2>}

      {fixtures.length > 0 && fixtures.map(fixture => <Fixture key={fixture.id} fixture={fixture} fixtures={fixtures} divisions={divisions} showOrganisation={fixture.division && fixture.division.competition && fixture.division.competition.organisation_id != organisationId} />)}
      {fixtures.length == 0 && <h2 className="u-aux">No Upcoming Fixtures</h2>}
    </div>
  )
}
