import React from 'react'
import { Button, Headline } from './'


export default function Headlines({headlines}) {
  return (
    <div className="c-headlines">
      {headlines.map((h, index) =>
        <Headline key={h.id} headline={h} className={`
          c-headlines__${index+1}
          c-headline--${index < 2 ? 'regular' : 'minimal'}
        `} />
      )}

      <div className="c-headlines__more">
        <Button href="/news" type="primary" block>{'More News →'}</Button>
      </div>
    </div>
  )
}
