import React from 'react'
import { Button, ButtonGroup } from './'
import { fetchDivisions } from '../api/divisions'
import { fetchResults } from '../api/fixtures'
import { fetchOrganisation } from '../api/organisations'
import { fetchSeasons } from '../api/seasons'
import { fetchCompetitions } from '../api/competitions'
import domainOrganisation from '../config/organisation';
import { fetchPages } from '../api/pages';

export default (Page, addAll = true) => {
    return class extends React.Component {
        static async getInitialProps({req, query}) {
          const getSeasonId = function(query, season) {
            if (query.season) {
              return query.season
            }
            const openSeasons = seasons.data.filter((s) => s.status == 'open')
            if (openSeasons.length > 0) {
              return openSeasons[openSeasons.length - 1].id
            }
          }
          const organisationId = domainOrganisation(req)
          const organisation = await fetchOrganisation(organisationId)
          const pages = await fetchPages(organisationId)
          const seasons = await fetchSeasons(organisationId);
          const seasonId = getSeasonId(query, seasons)
          const competitions = await fetchCompetitions(organisationId, seasonId);
          const activeCompetition = competitions.data.find((c) => c.is_active)
          let competitionId = query.competition || (addAll && 'all') || (activeCompetition && activeCompetition.id) || competitions.data[0].id
          
          const divisions = await fetchDivisions(organisationId, competitionId);
          console.log(divisions)
          if (addAll) {
            divisions.data.unshift({ id:'all', name:'All' })
            competitions.data.unshift({ id:'all', name:'All' })
          }
          const divisionId = query.division || (addAll && 'all') || (divisions.data[0] && divisions.data[0].id);

          let props = {
            divisions: divisions,
            seasons: seasons,
            competitions: competitions,
            divisionId: divisionId,
            seasonId: seasonId,
            competitionId: competitionId,
            organisationId: organisationId,
            organisation: organisation,
            pages: pages
          }


          if(Page.getInitialProps) {

            const ctx = { req, query, props}
            props = Object.assign(props, await Page.getInitialProps(ctx))
          }

          return props
        }

        render() {
            return <Page {...this.props}/>
        }

    }
}
