import React from 'react'
import { format } from 'date-fns'
import Link from './Link'

export default function Headline({ className, headline }) {
  const imageMatch = headline.html.match(/<img[^>]+src="([^">]+)"/)
  let imageSrc="/static/photo.png"
  if (imageMatch) {
    imageSrc = imageMatch[1] ;
  }
  return (
    <Link className={`c-headline ${className}`} href={`/news`}>
      <img className="c-headline__image" src={imageSrc} />
      <div className="c-headline__overlay">
        <div className="c-headline__title">{headline.headline}</div>
        <div className="c-headline__date">{format(headline.created_at, 'Do MMMM YYYY')}</div>
      </div>
    </Link>
  )
}
