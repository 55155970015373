import React from 'react'
import Link from './Link'

export default function Nav({ className, pages }) {
  return (
    <ul className={`c-nav ${className}`}>
      <li className="c-nav__item">
        <Link prefetch className="c-button c-button--subtle" href="/news">News</Link>
      </li>
      <li className="c-nav__item">
        <Link prefetch className="c-button c-button--subtle" href="/fixtures">Fixtures</Link>
      </li>
      <li className="c-nav__item">
        <Link prefetch className="c-button c-button--subtle" href="/results">Results</Link>
      </li>
      <li className="c-nav__item">
        <Link prefetch className="c-button c-button--subtle" href="/team-standings">Standings</Link>
      </li>
      <li className="c-nav__item c-nav__dropdown u-show-desktop">
        <a className="c-button c-button--subtle">Stats</a>
        <div className="c-nav__dropdown-content">
          <Link prefetch className="c-button c-button--subtle" href="/player-stats">Player Stats</Link>
          <Link prefetch className="c-button c-button--subtle" href="/goalie-stats">Goalie Stats</Link>
        </div>
      </li>
      <li className="c-nav__item u-hide-desktop">
          <Link prefetch className="c-button c-button--subtle" href="/player-stats">Player Stats</Link>
      </li>
      <li className="c-nav__item u-hide-desktop">
          <Link prefetch className="c-button c-button--subtle" href="/goalie-stats">Goalie Stats</Link>
      </li>
      <li className="c-nav__item">
        <Link prefetch className="c-button c-button--subtle" href="/photos">Photos</Link>
      </li>
      <li className="c-nav__item">
        <Link prefetch className="c-button c-button--subtle" href="/documents">Documents</Link>
      </li>
      <li className="c-nav__item">
        <Link prefetch className="c-button c-button--subtle" href="/contact">Contact</Link>
      </li>
      <li className="c-nav__item">
        <Link prefetch className="c-button c-button--subtle" href="/map">Map</Link>
      </li>
      {pages.data.length > 0 &&
        <li className="c-nav__item c-nav__dropdown u-show-desktop">
          <a className="c-button c-button--subtle">More</a>
          <div className="c-nav__dropdown-content">
            {pages.data.map((f) =>
              <Link key={`${f.id}-dd`} prefetch className="c-button c-button--subtle" href={`/p/${f.id}/${f.headline}`}>{f.headline}</Link>
            )}
          </div>
        </li>
       
      }
      {pages.data.length > 0 && pages.data.map((f) =>
          <li key={f.id} className="c-nav__item u-hide-desktop">
          <Link prefetch className="c-button c-button--subtle" href={`/p/${f.id}/${f.headline}`}>{f.headline}</Link>
          </li>
      )}
      <li className="c-nav__item">
        <Link className="c-button c-button--subtle" href="https://admin.sportilyapp.com">Login</Link>
      </li>
      <li className="c-nav__item">
        <Link className="c-button c-button--primary" href="/register">Register</Link>
      </li>
    </ul>
  )
}
