import { api } from './api';
import { getValue } from '../cache';

export async function fetchSeasons(organisationId) {
    const key = 'seasons-' + organisationId
    const cacheTTL = 3.6e+6

    const value = await getValue(key, async function() {
      const apiInst = await api();
      const response = await apiInst.get('seasons?organisation_id=' + organisationId + '?include=competitions');
      return response.data;
    }, cacheTTL)

    return value
}
